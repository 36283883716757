<template>
  <div id="app">
    <!-- 头部导航栏 -->
    <div class="main">
      <router-view/>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #000000;
}
.main {
  min-height: 100vh;
  border: 1px solid transparent;
  box-sizing: border-box;
}
</style>
