<template>
  <div class="home">
    <div class="center">
		<div class="middle">
			<div class="centent">
				<div class="img"></div>
				<div class="right">
					<div class="logo"></div>
					<div class="txt">
						<p>栖约App专注中国茶及茶周边鉴别领域，坚持以“先试喝，再购买”的购物流程，为消费者带来安全的购茶体验。让茶友可以放心购买到品类丰富的，经过行家鉴别的中国茶。社区聚集热衷于品茶、赏器与收藏的茶友们，围绕品种、品质、山场、匠人，展开平等友好的品鉴与交流。</p>
					</div>
					<div class="buttonBg">
						<div class="leftbutton" v-on:click="learnMore()"></div>
						<div class="rightbutton" v-on:click="learnMore()"></div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="text1"><a href="https://beian.miit.gov.cn/#/Integrated/index"  style="color: #939393; text-decoration: none;">浙ICP备2023007905号-1</a>
				<a> ｜ 食品经营许可证 JY13301830266310 ｜</a>
				<a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33018302001638" style="text-decoration:none; color: #939393;"><img src="../assets/imgs/beian.jpg" style="width:12px; height: 12px;"/> 浙公网安备 33018302001638号</a>
				</div>
				<div class="text1">CopyRight @2023 杭州栖约科技有限公司 All Rights Reserved</div>
				<div class="text1">Hangzhou Qiyue Technology Co., Ltd</div>
			</div>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      itemData: [
      ]
    }
  },
  methods: {
    learnMore() {
      this.$message.info('开发中,敬请期待!')
    },
    handleItem(index) {
      this.$router.push({
        path: '/service',
        query: {
          index: index
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
	
	display: flex;
	justify-content: center;
	align-items: center;
	background: url('../assets/imgs/center.jpg') no-repeat center;
	.center{
		height: 100vh;
		width: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		.middle {
			
			min-height: 100vh;
			.centent{
				display: flex;
				align-items: center;
				text-align: center;
				width: 100%;
				min-height: calc(100vh - 120px);
				.img{
					width: 30%;
					min-height: 45vh;
					margin-left: 120px;
					background: url(../assets/imgs/phone.png) center center no-repeat;
					background-size: 100% 100%;
				}
				.right{
					margin-right: 80px;
					width: 70%;
					min-height: 45vh;
					margin-left: 80px;
					.logo{
						
						width: 70%;
						min-height: 10vh;
						margin-left: 0px;
						background: url(../assets/imgs/logo.png) center center no-repeat;
					}
					.txt{
						
						width: 90%;
						color: #FFFFFF;
						font-size: 14px;
						letter-spacing: 2px;
						line-height: 2;
						text-align: left;
					}
					.buttonBg {
						display: flex;
						width: 80%;
						height: 20%;
						margin-top: 30px;
						justify-content: flex-start;
						.leftbutton{
							width: 90px;
							height: 33px;
							background: url(../assets/imgs/left.png);
						}
						.rightbutton{
							width: 90px;
							height: 33px;
							margin-left: 40px;
							background: url(../assets/imgs/right.png);
						}
					}
				}
				
			}
			
			.bottom{
			
				text-align: center;
				width: 100%;
				height: 120px;
				color: #939393;
				font-size: 12px;
				line-height: 20px;
				.text1{
					width: 100%;
				}
			}
		}
	}
}
</style>
